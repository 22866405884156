import './App.css';
import Home from './components/pages/Home';

function App() {
  return (
    <>

      <Home />



    </>
  );
}

export default App;
